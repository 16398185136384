import Vue from 'vue'
import axios from 'axios'

const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

Vue.use({
  install(Vue) {
      Vue.prototype.$http = axios.create({
          baseURL: VUE_APP_API_URL
      })

      Vue.prototype.$http.interceptors.request.use(config => {
        const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
        const json = JSON.parse(id)
        const user = json?.user
        //let use_auth = window.location.href.indexOf('/cotacao') == -1 //não usar autenticação nas requisições da tela de visualização da cotação
        let use_auth = true //se tiver usuario, sempre usar autenticação
        if (user && use_auth) {
          config.headers.Authorization = `Bearer ${user.user_token}`
        }
        return config
      }, error => Promise.reject(error))
  }
})
