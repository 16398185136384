<template>
  <div class="wrapper">
    <overlay :show="!loadingAll" rounded="sm">
      <Header :validade="validade" :vencida="vencida" />
      <div class="content-wrapper"
        :class="{ 'ml-0': (source == 'iframe') }">
        <div class="row">
           <div class="col-sm-12">
              <div class="card card-cabecalho">
                <div class="card-header">
                  <b-row>
                    <b-col cols="5" class="text-length p-0">
                      <a href="#" class="btn btn-success btn-sm">
                        <span class="no-label">Cód. Cotação: </span>
                        <span class="label">Cód.: </span>
                        <span>{{cotacaoId}}</span>
                      </a>
                    </b-col>
                    <b-col cols="2" md="7" class="text-right p-0">
                      <b-button-group class="mr-1" v-if="!vencida">
                        <b-button @click="modalOpen('impressao')"
                        title="Imprimir" size="sm" class="">
                          <b-spinner
                              small
                              variant="success"
                              v-if="!getLoadAllData()"
                              title="Aguarde o carregamento dos dados"
                          ></b-spinner>
                          <b-icon icon="printer" aria-hidden="true" v-if="getLoadAllData()"></b-icon> <span class="no-label">Imprimir</span>
                        </b-button>
                      </b-button-group>
                    </b-col>
                    <b-col cols="5" class="text-right d-xs-block d-md-none p-0 mb-1">
                      <a href="#" class="btn btn-warning btn-sm" v-if="vencida">
                        <span>Status: Vencida</span>
                      </a>
                      <a href="#" class="btn btn-success btn-sm" v-else-if="validade">
                        <span class="no-label">Valido Até: </span>
                        <span>{{validade | data }}</span>
                      </a>
                    </b-col>
                  </b-row>
                </div>
                <div class="card-body table-responsive">
                  <table class="table" v-if="!vencida">
                    <tbody>
                        <tr>
                          <td>
                              <table class="table p-0 m-0" width="100%" align="center" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                      <td align="left">
                                          <p>
                                            <font color="#666666" size="2" face="Arial, Helvetica, sans-serif">
                                              Para <font color="#007CC3"><strong>{{cliente_nome}}</strong></font><br>
                                              <span v-if="cliente_email">E-mail: <strong><font color="#007CC3">{{cliente_email}}</font></strong><br></span>
                                              De: <strong><font color="#007CC3">{{user.name}} (<a :href="`mailto:${user.email}`"><font color="#75CDFF">{{user.email}}</font></a>)</font></strong><br>
                                              <br>
                                              {{cotacaoUf}}, {{cotacaoData | dataLong }}<br>
                                              <br>
                                              Prezado(a) {{cliente_nome}},
                                            </font>
                                          </p>
                                          <div id="comentario" style="background-color:#F1F1F1;padding:10px;">
                                            <font color="#666666" size="2" face="Arial, Helvetica, sans-serif" :inner-html.prop="cotacaoMensagem"></font>
                                          </div>
                                      </td>
                                    </tr>
                                </tbody>
                              </table>
                          </td>
                        </tr>
                        <tr v-if="cotacaoArquivos && cotacaoArquivos.length">
                          <td height="20" align="center">
                              <table class="table p-0 m-0" width="100%" align="center" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                      <td valign="top">
                                        <font color="#666666" size="2" face="Arial, Helvetica, sans-serif">
                                          Arquivos anexados:
                                        </font>
                                      </td>
                                      </tr>
                                      <tr>
                                       <td valign="top">
                                        <template v-for="arquivo in cotacaoArquivos">
                                          <span class="preview mr-3" :key="arquivo.id">
                                            <b-icon
                                              icon="file-earmark-arrow-down"
                                              aria-hidden="true"
                                              v-if="loadingSession"
                                              font-scale="2"
                                            ></b-icon>
                                            <a :href="arquivo.visualizar"
                                                class="ml-2"
                                                style="color:#333;font-size:12px;text-decoration: underline;"
                                                target="_blank"
                                                :title="`Clique aqui para baixar o arquivo ${arquivo.nome}`">
                                                {{arquivo.nome}}
                                            </a>
                                          </span>
                                        </template>
                                      </td>
                                    </tr>
                                </tbody>
                              </table>
                          </td>
                        </tr>
                        <tr>
                          <td>
                              <table class="table p-0 m-0" width="95%" align="center" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                    <tr>
                                      <td><font color="#666666" size="2" face="Arial, Helvetica, sans-serif">Segue Abaixo:</font></td>
                                    </tr>
                                </tbody>
                              </table>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                  <h3 class="text-center" v-else>Cotação vencida!</h3>
                </div>
              </div>
            </div>
          <div class="col-12" v-if="!vencida">
            <card>
              <div class="row">
                <div class="col-sm-12 d-md-none">
                  <div class="info-plano-selecionado">
                    <h4 style="font-size: 18px">{{ title }}</h4>
                    <h6>{{ subtitle }}</h6>
                  </div>
                </div>
                <div class="col-sm-12 d-md-none mb-3">
                  <div class="card-container logo-operadora">
                    <div class="card card-logo">
                      <div class="content">
                        <img :src="operadoraLogoTipo"
                          :alt="operadora.nome"
                          style="max-height: 100%;" />
                      </div>
                    </div>
                  </div>
                  <div class="info-plano-selecionado ml-2 mt-2">
                    <p>Compulsório: {{ textCom }}</p>
                    <p>Coparticipação: {{ textCop }}</p>
                    <p>Acomodação: {{ textAcom }}</p>
                    <p>Aceita MEI: {{ textMei }}</p>
                  </div>
                </div>
                <div class="col-xl-8 col-md-12 col-sm-12 d-none d-md-block align-self-start mb-3 px-0">
                  <div class="card-container logo-operadora">
                    <div class="card card-logo">
                      <div class="content">
                        <img :src="operadoraLogoTipo"
                          :alt="operadora.nome"
                          style="max-height: 100%;" />
                      </div>
                    </div>
                  </div>
                  <div class="info-plano-selecionado">
                    <h4>{{ title }}</h4>
                    <h6>{{ subtitle }}</h6>
                    <p>Compulsório: {{ textCom }}</p>
                    <p>Coparticipação: {{ textCop }}</p>
                    <p>Acomodação: {{ textAcom }}</p>
                    <p>Aceita MEI: {{ textMei }}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <b-tabs
                    content-class="mt-3"
                    v-model="tabIndex">
                    <b-tab title="Valores" v-if="showTabs('valores')" active>
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingValores" />
                      </div>
                      <div role="tablist" v-if="grupos.length" class="grupo">
                        <b-card no-body class="mb-1" v-for="grupo in search" :key="grupo.id">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block v-b-toggle="`accordion-${grupo.id}`" class="btn btn-link">
                              <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                              <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                              <div class="fz-14 mb-1" :inner-html.prop="grupo.nome | highlight(buscaValores)" />
                              <div class="mr-1">
                                <font-awesome-icon :icon="['fas', 'user-friends']" class="mr-1" />
                                <span class="mr-3">{{ grupo.min_vidas }} a {{ grupo.max_vidas }}</span>

                                <font-awesome-icon :icon="['fas', 'calendar-check']" class="mr-1" v-if="grupo.data_inicio" />
                                <span class="mr-3" v-if="grupo.data_inicio">A partir {{ grupo.data_inicio | data }}</span>
                                <font-awesome-icon :icon="['fas', 'hourglass-end']" class="mr-1" v-if="grupo.data_fim" />
                                <span class="mr-3" v-if="grupo.data_fim"> Até {{ grupo.data_fim | data }}</span>

                                <font-awesome-icon :icon="['fas', 'hand-holding-usd']" class="mr-1" v-if="grupo.coparticipacao" />
                                <span class="mr-3" v-if="grupo.coparticipacao">Coparticipação</span>
                                <span class="mr-3" v-if="grupo.coparticipacao_porcentagem">{{ grupo.coparticipacao_porcentagem | porcentagem }}</span>
                                <font-awesome-icon :icon="['fas', 'globe']" class="mr-1" v-if="grupo.compulsorio" />
                                <span v-if="grupo.compulsorio">Compulsório</span>
                                <font-awesome-icon :icon="['fas', 'user-tie']" class="mr-1" v-if="grupo.mei" />
                                <span v-if="grupo.mei">Aceita MEI</span>
                              </div>
                            </b-button>
                          </b-card-header>
                          <b-collapse :visible="grupos.length == 1" :id="`accordion-${grupo.id}`" accordion="valores-accordion" role="tabpanel">
                            <b-card-body>
                              <b-table striped
                                sticky-header
                                no-border-collapse
                                responsive
                                :fields="normalizeFields(grupo.fields)"
                                :items="grupo.items"
                              >
                                <template #head(plano)>
                                    <div class="text-nowrap">Planos</div>
                                </template>
                                <template #head(idade)>
                                    <div class="text-nowrap">Idade</div>
                                </template>
                                <template #head()="scope">
                                    <div>
                                    {{ scope.label }}
                                    </div>
                                </template>
                                <template #cell(idade)="scope">
                                    <div class="text-nowrap">
                                    {{ scope.value | idade }}
                                    </div>
                                </template>
                                <template #cell(plano)="scope">
                                <div>
                                    {{ scope.value }}
                                </div>
                                </template>
                                <template #cell()="scope">
                                    <div class="text-nowrap">
                                    {{ scope.value | dinheiro}}
                                    </div>
                                </template>
                              </b-table>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <p v-else-if="!loadingValores" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Nenhum grupo encontrado!
                      </p>
                    </b-tab>
                    <b-tab title="Rede" v-if="showTabs('rede')">
                      <b-form-group
                        id="busca-redes"
                        label-for="busca-redes"
                        label-align="left"
                        class="d-print-none mb-1">
                        <b-form-input
                          id="busca-redes"
                          type="text"
                          v-model="buscaRedes"
                          placeholder="Pesquisar..."
                          class="mb-2" />
                      </b-form-group>
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingRegiao" />
                      </div>
                      <div role="tablist" v-if="regioes.length">
                        <div v-for="(regiao, rindex) in search" :key="rindex">
                          <h4 :inner-html.prop="regiao.nome | highlight(buscaRedes)" class="mt-1 mb-2"></h4>
                          <b-card no-body class="ml-1 mr-1 mb-1" v-for="(cidade, cindex) in regiao.cidades" :key="cindex">
                            <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block
                                v-b-toggle="`accordion-regiao-${regiao.id}-${cidade.id}`"
                                class="btn btn-link">
                                <span class="fz-14" :inner-html.prop="cidade.nome | highlight(buscaRedes)" />
                                <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                                <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                              </b-button>
                            </b-card-header>
                            <b-collapse :id="`accordion-regiao-${regiao.id}-${cidade.id}`"
                              accordion="regioes-accordion"
                              role="tabpanel">
                              <b-card-body>
                                <div ref="body" class="redes" :style="[baseStylesRede]">
                                  <button class="scroll-button float-left" @click="sideScroll('left')" v-if="showLeftArrow">
                                      <b-icon icon="caret-left-fill"></b-icon>
                                  </button>
                                  <button icon="caret-right-fill" class="scroll-button float-right" @click="sideScroll('right')" v-if="showRightArrow">
                                      <b-icon icon="caret-right-fill"></b-icon>
                                  </button>
                                  <table ref="bodyOverflow" class="table b-table table-striped b-table-no-border-collapse host" :class="'overflowing-' + overflow">
                                    <template v-for="(tipo, i) in Object.keys(cidade.credenciados)">
                                      <thead :key="i">
                                        <tr>
                                          <th class="eixo">
                                            {{ tipo }}
                                          </th>
                                          <th v-for="(plano, index) in cabCredPlanos(cidade.credenciados)" :key="index">
                                            {{ i == 0 ? plano.nome : '' }}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody :key="i">
                                          <tr :class="{ 'alternate': (crindex % 2 == 0) }" v-for="(credenciado, crindex) in cidade.credenciados[tipo]" :key="crindex">
                                            <td class="eixo">
                                              <b-link href="#" v-b-modal.modal-localizacao
                                                variant="link"
                                                class="text-primary"
                                                sm
                                                @click="credenciadoSelecionado = credenciado"
                                                :inner-html.prop="credenciado.resumo ? credenciado.resumo : credenciado.nome | highlight(buscaRedes)">
                                              </b-link>
                                            </td>
                                            <td v-for="(plano, index) in cabCredPlanos(cidade.credenciados)" :key="index">
                                              <span :inner-html.prop="cobCredPlanos(plano, credenciado)" v-b-popover.hover="{content:obsCredPlanos(plano, credenciado), html:true, customClass: 'credenciado_obs'}" />
                                              <span
                                                  v-if="obsCredPlanos(plano, credenciado)"
                                                  variant="link"
                                                  class="shadow-none"
                                                  title=""
                                                >*</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                    </template>
                                  </table>
                                </div>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <p v-else-if="!loadingRegiao" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i><br>
                        Nenhuma rede encontrada!
                      </p>
                    </b-tab>
                    <b-tab title="Reembolso" v-if="showTabs('reembolso')">
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingReembolsos" />
                      </div>
                      <div ref="body" role="tablist" v-if="reembolsos.length" class="reembolsos" :style="[baseStylesReembolso]">
                        <button class="scroll-button float-left" @click="sideScroll('left')" v-if="showLeftArrow">
                            <b-icon icon="caret-left-fill"></b-icon>
                        </button>
                        <button icon="caret-right-fill" class="scroll-button float-right" @click="sideScroll('right')" v-if="showRightArrow">
                            <b-icon icon="caret-right-fill"></b-icon>
                        </button>
                        <table ref="bodyOverflow" class="table table-sm host table-striped b-table b-table-no-border-collapse" :class="'overflowing-' + overflow">
                          <thead>
                            <tr>
                              <th class="eixo">Descrição do Procedimento</th>
                              <th v-for="plano in planos" :key="plano.id">
                                {{ plano.nome }}
                                <small v-if="semReembolso(plano.id)" style="color: #dc3545">
                                  <br/> <font-awesome-icon :icon="['fas', 'ban']"  /> Sem Reembolso
                                </small>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr :class="{ 'alternate': (index % 2 == 0) }" v-for="(reembolso, index) in reembolsosProcedimentos" :key="index">
                              <th class="eixo">{{ reembolso.nome }}</th>
                              <td v-for="plano in planos" :key="plano.id">
                                {{ reembolsoValores(plano.id, reembolso.aId) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p v-else-if="!loadingReembolsos" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui opção de reembolso.
                      </p>
                    </b-tab>
                    <b-tab title="Carência" v-if="showTabs('carencia')">
                      <b-form-group
                        id="busca-carencia"
                        label-for="busca-carencia"
                        label-align="left"
                        class="d-print-none">
                        <b-form-input
                          id="busca-carencia"
                          type="text"
                          placeholder="Pesquisar..."
                          v-model="busca" />
                      </b-form-group>
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingCarencias" />
                      </div>
                      <div class="carencias" role="tablist" v-if="carenciasIsArray.length > 0">
                        <div v-for="(carencia, cindex) in search" :key="cindex">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block
                                v-b-toggle="`accordion-carencia-${slugify(carencia.catNome)}-${cindex}`"
                                class="btn btn-link">
                                <span class="fz-14 mb-1" :inner-html.prop="carencia.catNome | highlight(busca)" />
                                <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                                <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                              </b-button>
                            </b-card-header>
                            <b-collapse :visible="carenciasIsArray.length == 1" :id="`accordion-carencia-${slugify(carencia.catNome)}-${cindex}`"
                              accordion="carencias-accordion"
                              role="tabpanel">
                              <b-card-body class="table-responsive">
                                <div class="mb-1" :inner-html.prop="carencia.itemConteudo | highlight(busca)" />
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <p v-else-if="!loadingCarencias" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui opção de carências.
                      </p>
                    </b-tab>
                    <b-tab title="Proc. Obrigatório" v-if="showTabs('proc_obrigatorios')">
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingProcedimentos" />
                      </div>
                      <div role="tablist" v-if="proc_obrigatorios.length">
                        <b-card no-body class="mb-1" v-for="proc_obrigatorio in search" :key="proc_obrigatorio.cobId">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block v-b-toggle="`accordion-proc-obri-${proc_obrigatorio.cobId}`" class="btn btn-link">
                              <span class="fz-14 mb-1">{{ proc_obrigatorio.nome }}</span>
                              <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                              <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                            </b-button>
                          </b-card-header>
                          <b-collapse :visible="proc_obrigatorios.length == 1" :id="`accordion-proc-obri-${proc_obrigatorio.cobId}`"
                            accordion="proc-obri-accordion"
                            role="tabpanel">
                            <b-card-body>
                              <div role="tablist" class="table-responsive">
                                <table class="table table-sm host table-striped b-table b-table-no-border-collapse">
                                  <thead>
                                    <tr>
                                      <th style="width: 200px;">Procedimento</th>
                                      <th style="width: 200px;" v-for="plano in planos" :key="plano.id">
                                        {{ plano.nome }}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(procedimento, index) in proc_obrigatorio.procs" :key="index">
                                      <td style="min-width: 200px;">{{ procedimento.nome }}</td>
                                      <td style="min-width: 200px;" v-for="plano in planos" :key="plano.id">
                                        {{ procedimentoObrigatorio(plano.id, procedimento) }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <p v-else-if="!loadingProcedimentos" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui opções de procedimentos obrigatórios.
                      </p>
                    </b-tab>
                    <b-tab title="Proc. Diferenciados" v-if="showTabs('proc_diferenciados')">
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingProcedimentos" />
                      </div>
                      <div role="tablist" v-if="proc_diferenciados.length">
                        <b-card no-body class="mb-1" v-for="proc_diferenciado in proc_diferenciados" :key="proc_diferenciado.id">
                          <b-card-header header-tag="header" class="p-0" role="tab">
                            <b-button block v-b-toggle="`accordion-proc-obri-${proc_diferenciado.id}`" class="btn btn-link">
                              <span class="fz-14 mb-1">{{ proc_diferenciado.nome }}</span>
                              <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                              <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                            </b-button>
                          </b-card-header>
                          <b-collapse :visible="proc_diferenciados.length == 1" :id="`accordion-proc-obri-${proc_diferenciado.id}`"
                            accordion="proc-obri-accordion"
                            role="tabpanel">
                            <b-card-body class="table-responsive">
                              <div v-html="proc_diferenciado.doc"></div>
                              <p v-if="!proc_diferenciado.doc" class="text-center h5 mt-4">
                                <i class="ti-info-alt h5"></i> <br>
                                Nenhum documento encontrado!
                              </p>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </div>
                      <p v-else-if="!loadingProcedimentos" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui opção de procedimentos diferenciados
                      </p>
                    </b-tab>
                    <b-tab title="Docs" v-if="showTabs('docs')">
                      <b-form-group
                        id="busca-docs"
                        label-for="busca-docs"
                        label-align="left"
                        class="d-print-none">
                        <b-form-input
                          id="busca-docs"
                          type="text"
                          placeholder="Pesquisar..."
                          v-model="busca" />
                      </b-form-group>
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingDocs" />
                      </div>
                      <div class="docs" role="tablist" v-if="docsIsArray.length > 0">
                        <div v-for="(doc, dindex) in search" :key="dindex">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block
                                v-b-toggle="`accordion-docs-${slugify(doc.catNome)}-${dindex}`"
                                class="btn btn-link">
                                <span class="fz-14 mb-1" :inner-html.prop="doc.catNome | highlight(busca)" />
                                <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                                <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                              </b-button>
                            </b-card-header>
                            <b-collapse :visible="docsIsArray.length == 1" :id="`accordion-docs-${slugify(doc.catNome)}-${dindex}`"
                              accordion="docs-accordion"
                              role="tabpanel">
                              <b-card-body class="table-responsive">
                                <div class="mb-1" :inner-html.prop="doc.itemConteudo | highlight(busca)" />
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <p v-else-if="!loadingDocs" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui opção de documentos.
                      </p>
                    </b-tab>
                    <b-tab title="+Infos" v-if="showTabs('info')">
                      <b-form-group
                        id="busca-infos"
                        label-for="busca-infos"
                        label-align="left"
                        class="d-print-none">
                        <b-form-input
                          id="busca-infos"
                          type="text"
                          placeholder="Pesquisar..."
                          v-model="busca" />
                      </b-form-group>
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingInfos" />
                      </div>
                      <div class="infos" role="tablist" v-if="infosIsArray.length > 0">
                        <div v-for="(info, iindex) in search" :key="iindex">
                          <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-0" role="tab">
                              <b-button block
                                v-b-toggle="`accordion-info-${slugify(info.catNome)}-${iindex}`"
                                class="btn btn-link">
                                <span class="fz-14 mb-1" :inner-html.prop="info.catNome | highlight(busca)" />
                                <font-awesome-icon :icon="['fas', 'chevron-down']" style="float: right;" />
                                <font-awesome-icon :icon="['fas', 'chevron-up']" style="float: right;"/>
                              </b-button>
                            </b-card-header>
                            <b-collapse :visible="infosIsArray.length == 1" :id="`accordion-info-${slugify(info.catNome)}-${iindex}`"
                              accordion="infos-accordion"
                              role="tabpanel">
                              <b-card-body class="table-responsive">
                                <div class="mb-1" :inner-html.prop="info.itemConteudo | highlight(busca)" />
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                      <p v-else-if="!loadingInfos" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui mais informações.
                      </p>
                    </b-tab>
                    <b-tab title="Arquivos" v-if="showTabs('arquivos')">
                      <div class="text-center">
                        <loader
                          :width="'150px'"
                          :height="'150px'"
                          v-if="loadingArquivos" />
                      </div>
                      <div role="tablist" v-if="arquivos.length" class="table-responsive">
                        <table class="table b-table table-striped b-table-no-border-collapse">
                          <tbody>
                            <tr v-for="(arquivo, index) in search" :key="index">
                              <td width="10">
                                  <b-icon icon="file-earmark-richtext" class="h4 text-danger mt-0 mb-0"></b-icon>
                              </td>
                              <td class="text-left">
                                <b-link :href="arquivo.url" squared variant="btn" target="_blank">
                                  <div class="mt-1 mb-1" :inner-html.prop="arquivo.nome | highlight(busca)" />
                                </b-link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p v-else-if="!loadingArquivos" class="text-center h5 mt-4">
                        <i class="ti-info-alt h4"></i> <br>
                        Este plano não possui arquivo.
                      </p>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </card>
            <div class="row legendas" v-if="tabs[tabIndex] == 'rede'">
              <div class="col-md-3">
                Legendas: <b>H</b> - Internação Efetiva,
              </div>
              <div class="col-md-2">
                <b>PS</b> - Pronto Socorro,
              </div>
              <div class="col-md-2">
                <b>M</b> - Maternidade,
              </div>
              <div class="col-md-2">
                <b>AMB</b> - Ambulatório,
              </div>
              <div class="col-md-2">
                <b>SIM</b> - Possui atendimento
              </div>
            </div>
          </div>
          <div class="col-sm-12" v-if="!vencida">
            <div class="card card-aviso">
              <div class="card-header">
                <p class="card-title text-danger">IMPORTANTE - LEIA COM ATENÇÃO</p>
              </div>
              <div class="card-body text-danger">
                <p class="fz-12">As informações referentes a Preços, Redes, Carências, Documentações, Reembolsos e Condições Contratuais são <b>determinadas pelas</b> operadoras, seguradoras, 
                    administradoras ou outras entidades responsáveis e <b>deverão ser verificadas e conferidas junto</b> à respectiva <b>antes da CONTRATAÇÃO.</b></p>
                <p class="fz-12">Esta é uma <b>ferramenta de simulação</b> destinada a auxiliar no processo de pesquisa. Contudo, é importante destacar que <b>não nos responsabilizamos por possíveis 
                alterações que sejam feitas sem prévio aviso</b>. Para maiores informações e rede credenciada, consulte o site oficial da operadora. Quaisquer outras <b>dúvidas consulte o 
                corretor</b> que lhe enviou esta simulação.</p>
                <p class="fz-12"><b>Ressaltamos o nosso compromisso com a precisão das informações fornecidas</b>, no entanto, reservamo-nos o direito de corrigir eventuais erros identificados, esclarecendo 
                que tais correções <b>não estão vinculadas à efetivação do serviço</b>, que ocorrerá exclusivamente mediante a assinatura do contrato.</p>
                <p class="fz-12"><i>Aos valores apresentados não estão sendo somadas as taxas (exceto quando informado).</i></p>
              </div>
            </div>
          </div>
        </div>
        <b-modal id="modal-localizacao"
          size="md"
          @show="showModal"
          @hidden="resetModal"
          hide-footer>
          <template v-slot:modal-title>
            Detalhes do {{ credenciado.tipo }}
          </template>
          <div class="row">
            <div class="col-12">
              <h6>{{ credenciado.nome }}</h6>
              <p class="mb-1">
                <small>
                  <b-icon icon="telephone-fill" /> <a :href="`tel:${onlyNumber(credenciado.telefone)}`">{{ credenciado.telefone }}</a>
                </small>
              </p>
              <p class="mb-1">
                <small>
                  <b-icon icon="cursor-fill" /> {{ credenciado.endereco }}
                </small>
              </p>
              <p class="mb-1">
                <small>
                  <b-icon icon="house-door-fill" /> <a :href="`${credenciado.site}`" target="_blank">{{ credenciado.site }}</a>
                </small>
              </p>
            </div>
            <div class="col-12 map" :inner-html.prop="onlyHttps(credenciado.maps)">
            </div>
          </div>
        </b-modal>
        <ModalContato
          :hash="hash"
          :cotacao="cotacaoId"
          :codigo="indicacao"
          :cliente_nome="cliente_nome"
          :cliente_email="cliente_email"
          :cliente_telefone="cliente_telefone" />
      </div>
      <Sidebar :user="user" v-if="source != 'iframe'"/>
      <Footer />
    </overlay>
  </div>
</template>
<script>
import { StatsCard } from "@/components/index"
import { PaperTable } from "@/components"
import Share from "@/components/Share"
import ModalFilter from "@/components/ModalFilter"
import TravelMap from "@/components/TravelMap"
import FlexTableHeaderScroll from '@/components/FlexTableHeaderScroll'
import Overlay from '@/components/Overlay'
import Loader from '@/components/Loader'

import { mapActions, mapGetters } from 'vuex'

import Header from '@/layout/cotacao/Header'
import Sidebar from '@/layout/cotacao/Sidebar'
import Footer from '@/layout/cotacao/Footer'
import ModalContato from '@/layout/cotacao/ModalContato'

export default {
  props: ['hash'],
  components: {
    StatsCard,
    PaperTable,
    TravelMap,
    Share,
    ModalFilter,
    Header,
    Sidebar,
    Footer,
    ModalContato,
    Overlay,
    Loader
  },
  data() {
    return {
      filters: [],
      filtros: {
        tipo_plano: null,
        contratacao: null,
        coparticipacao: null,
        compulsorio: null,
        acomodacao: null,
        profissao: null,
        cidade: null,
        credenciado: null
      },
      loadingValores: false,
      titulo: 'Tabelas',
      subtitulo: 'Selecione as informações abaixo para filtrar',
      busca: '',
      buscaValores: '',
      buscaRedes: '',
      buscaReembolsos: '',
      user: {
        id: '',
        aplicativo: '',
        barra_url: '',
        cargo: '',
        cartao: '',
        email: '',
        empresa: '',
        login: '',
        logotipo: '',
        logotipo_usuario: '',
        name: '',
        redes: {
          facebook: '',
          google: '',
          instagram: '',
          linkedin: '',
          twitter: '',
          youtube: '',
        },
        site: '',
        status: '',
        telefones: [],
        uf: ''
      },
      cotacaoId: '',
      indicacao: '',
      cliente_nome: '',
      cliente_email: '',
      cliente_telefone: '',
      cotacaoData: '',
      cotacaoUf: '',
      cotacaoTitulo: '',
      cotacaoMensagem: '',
      cotacaoArquivos: [],
      validade: '',
      vencida: '',
      operadora: {
        nome: '',
        logotipoavo: '',
        logotipo: '',
        cidade: { id: '', cidade: '', uf: '' },
        tipo_plano: 's',
        coparticipacao: 2,
        compulsorio: 2,
        acomodacao: 2,
      },
      produto: {
        id: '',
        nome: ''
      },
      grupos: [],
      planosPorRede: [],
      tabIndex: 0,
      administradora: null,
      entidade: null,
      valores: [],
      regioes: [],
      cidades: [],
      planos: [],
      regiao: null,
      redes: [],
      tab: null,
      tabs: [],
      tabsObj: [],
      localidades: [],
      credenciadoSelecionado: null,
      credenciado: {
        nome: '',
        email: '',
        site: '',
        enderecos: [],
        telefones: []
      },
      reembolsos: [],
      carenciasHtml: '',
      carenciasIsArray: [],
      proc_obrigatorios: [],
      proc_diferenciados: [],
      docsHtml: '',
      docsIsArray: [],
      infosHtml: '',
      infosIsArray: [],
      arquivos: [],
      data: null,
      source: null
    };
  },
  mixins: [FlexTableHeaderScroll],
  methods: {
    ...mapActions('share', ['cotacao']),
    ...mapActions('regiao', ['buscaRegiao']),
    ...mapActions('rede', ['buscaRede']),
    ...mapActions('reembolso', ['buscaReembolso']),
    ...mapActions('carencia', ['buscaCarencia']),
    ...mapActions('procedimento', ['buscaProcedimentos']),
    ...mapActions('docs', ['buscaDocs']),
    ...mapActions('info', ['buscaInfos']),
    ...mapActions('arquivo', ['buscaArquivos']),
    ...mapActions('modalFilter', ['load']),
    ...mapActions('notificar', ['enviar']),
    ...mapActions('local', ['save']),
    async recuperaRedes() {
      if (this.regioes.length > 0) {
        return
      }
      let redes = this.grupos.map(grupo => grupo.redes)
        .reduce((acc, i) => acc.concat(i), [])
        .filter((value, index, self) => self.indexOf(value) === index)
      await this.buscaRegiao({
        uf: this.operadora.cidade.uf,
        cidade: '',// this.operadora.cidade ? this.operadora.cidade.id : '',
        credenciado: this.operadora.credenciado ? this.operadora.credenciado.tipo + this.operadora.credenciado.id : '',
        redes: redes
      }).then((regioes) => {
        this.regioes = regioes
      })
    },
    async loadFilters() {
      await this.load({
        tipoPlano: this.operadora.tipo_plano,
        filters: this.filters,
        valores: this.valores,
        regioes: this.regioes,
        reembolsos: this.reembolsos,
        carencias: this.carenciasIsArray,
        procObrigatorios: this.proc_obrigatorios,
        procDiferenciados: this.proc_diferenciados,
        docs: this.docsIsArray,
        infos: this.infosIsArray,
        arquivos: this.arquivos
      })
      this.filters = this.getFilters
      this.filtered()
    },
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
              map.set(key, [item]);
          } else {
              collection.push(item);
          }
      });

      return Array.from(map).map(p => ({
          key: p[0],
          value: p[1]
      }));
    },
    distinct(x, i, a) {
      return a.indexOf(x) === i
    },
    async recuperaReembolsos() {
      if (this.reembolsos.length > 0) {
        return
      }
      await this.buscaReembolso({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        cidade: this.operadora.cidade ? this.operadora.cidade.id : '',
        compulsorio: this.operadora.compulsorio == 2 ? '' : this.operadora.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.operadora.coparticipacao == 2 ? '' : this.operadora.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.operadora.acomodacao == 2 ? '' : this.operadora.acomodacao == 1 ? 'a' : 'e',
        credenciado: this.operadora.credenciado ? this.operadora.credenciado.id : '',
        planos: this.planos.map(p => p.id),
        administradora: this.administradora ? this.administradora.id : '',
        entidade: this.entidade ? this.entidade.id : '',
      }).then(reembolsos => {
        this.reembolsos = reembolsos
        let vm = this
        setTimeout(function () {
          vm.flexTableHeaderScroll()
        }, 2000)
      })
    },
    semReembolso(planoId) {
      return this.reembolsos.findIndex((r) => r.plId == planoId) == -1
    },
    reembolsoValores(planoId, reembolsoId) {
      let plKey = this.reembolsos.findIndex((r) => r.plId == planoId)
      let valor = 0.00
      if (plKey !== -1) {
        let plano = this.reembolsos[plKey]
        let vKey = plano.valores.findIndex((v) => v.aId == reembolsoId)
        if (vKey !== -1) {
          let reembolso = this.reembolsos[plKey].valores[vKey]
          valor = `${parseFloat(reembolso.valor).toFixed(2)}`.replace('.', ',')
        } else {
          valor = 'Não Inf.'
        }
      } else {
        valor = '-'
      }
      return valor
    },
    procedimentoObrigatorio(planoId, proc) {
      let procKey = proc.planos.findIndex((p) => p.plId = planoId)
      return procKey !== -1 ? 'SIM' : 'NÃO'
    },
    async recuperaCarencia() {
      if (this.carenciasHtml.length > 0 || this.carenciasIsArray.length > 0) {
        return
      }
      await this.buscaCarencia({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        administradora: this.administradora ? this.administradora.id : '',
        entidade: this.entidade ? this.entidade.id : '',
      }).then(carencias => {
        if (Array.isArray(carencias)) {
          this.carenciasIsArray = carencias
        } else {
          this.carenciasHtml = carencias
        }
      })
    },
    async recuperaProcedimentos() {
      if (this.proc_obrigatorios.length > 0 && this.proc_diferenciados.length) {
        return
      }
      await this.buscaProcedimentos({
        uf: this.operadora.cidade.uf,
        planos: this.planos.map(p => p.id)
      }).then(rprocedimentos => {
        this.proc_obrigatorios = rprocedimentos.obrigatorios
        this.proc_diferenciados = rprocedimentos.opcionais
      })
    },
    async recuperaDocs() {
      if (this.docsHtml.length > 0 || this.docsIsArray.length > 0) {
        return
      }
      await this.buscaDocs({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        administradora: this.administradora ? this.administradora.id : '',
        entidade: this.entidade ? this.entidade.id : '',
      }).then(docs => {
        if (Array.isArray(docs)) {
          this.docsIsArray = docs
        } else {
          this.docsHtml = docs
        }
      })
    },
    async recuperaInfo() {
      if (this.infosHtml.length > 0 || this.infosIsArray.length > 0) {
        return
      }
      await this.buscaInfos({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        planos: this.planos.map(p => p.id),
        administradora: this.administradora ? this.administradora.id : '',
        entidade: this.entidade ? this.entidade.id : '',
      }).then(infos => {
        if (Array.isArray(infos)) {
          this.infosIsArray = infos
        } else {
          this.infosHtml = infos
        }
      })
    },
    async recuperaArquivos() {
      if (this.arquivos.length > 0) {
        return
      }
      await this.buscaArquivos({
        uf: this.operadora.cidade.uf,
        produto: this.produto.id,
        tipo_plano: this.operadora.tipo_plano,
        contratacao: this.operadora.contratacao == 'a' ? 'pf' : this.operadora.contratacao,
        cidade: this.operadora.cidade ? this.operadora.cidade.id : '',
        compulsorio: this.operadora.compulsorio == 2 ? '' : this.operadora.compulsorio == 1 ? 's' : 'n',
        coparticipacao: this.operadora.coparticipacao == 2 ? '' : this.operadora.coparticipacao == 1 ? 's' : 'n',
        acomodacao: this.operadora.acomodacao == 2 ? '' : this.operadora.acomodacao == 1 ? 'a' : 'e',
      }).then(arquivos => {
        this.arquivos = arquivos
      })
    },
    resetModal() {
    },
    showModal() {
      this.credenciado = this.credenciadoSelecionado
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
    },
    gerarLink(cotacao) {
      console.log(cotacao)
    },
    getLoadAllData() {
      return !this.loadingValores &&
        !this.loadingRegiao &&
        !this.loadingReembolsos &&
        !this.loadingCarencias &&
        !this.loadingProcedimentos &&
        !this.loadingDocs &&
        !this.loadingInfos &&
        !this.loadingArquivos
    },
    async modalOpen(tipo) {
      if (this.getLoadAllData()) {
        if (tipo == 'impressao') {
          this.print()
        }
      }
    },
    print() {
      localStorage.setItem('operadora', JSON.stringify(this.operadora))
      localStorage.setItem('filters', JSON.stringify(this.filters))

      const filtros = this.filtros

      let routeData = this.$router.resolve({
        name: 'tabelaDetalhePrint',
        params: {
          id: 'cotacao'
        },
        query: {
          tipo_plano: filtros.tipo_plano,
          contratacao: filtros.contratacao,
          compulsorio: filtros.compulsorio == 2 ? '' : filtros.compulsorio == 1 ? 's' : 'n',
          coparticipacao: filtros.coparticipacao == 2 ? '' : filtros.coparticipacao == 1 ? 's' : 'n',
          acomodacao: filtros.acomodacao == 2 ? '' : filtros.acomodacao == 1 ? 'a' : 'e',
          profissao: filtros.profissao ? filtros.profissao : '',
          cidade: filtros.cidade ? filtros.cidade.id : '',
          credenciado: filtros.credenciado ? filtros.credenciado : '',
          hash: this.hash
        }
      })
      window.open(routeData.href, '_blank');
    },
    cabCredPlanos(credenciados) {
      let redes = Object.values(credenciados)
        .flat()
        .map(c => c.redes)
        .flat()
        .filter(this.distinct)
      let planos = [...this.planosPorRede]
      return planos.filter(plano => redes.indexOf(plano.rede_id) != 1)
    },
    cobCredPlanos(plano, credenciado) {
      return credenciado
        .redes
        .map(r => parseInt(r))
        .indexOf(parseInt(plano.rede_id)) != -1 ?
          credenciado.cob[plano.rede_id].split(',').map(c => `<span>${c}</span>`).join(',') :
          ' - '
    },
    obsCredPlanos(plano, credenciado) {
      if(!credenciado.hasOwnProperty('obs')) {
        return;
      }
      return credenciado.obs[plano.rede_id] ?
          credenciado.obs[plano.rede_id] :
          ''
    },
    onlyNumber: string => {
      return string ? string.replace(/\D/ig, '') : ''
    },
    onlyHttps: string => {
      return string ? string.replace(/(http:\/\/)/, 'https:\/\/') : ''
    },
    slugify: text => {
      text ? text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-') : ''
    },
    replaceSepecialChars(str) {
      return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    },
    async filtered() {
      let self = this
      this.operadora.filters = this.getFilters
      this.operadora.tabs = this.tabsObj.map(t => {
        if (t.key == 'valores') t.show = self.showValores ? 1 : 0
        if (t.key == 'rede') t.show = self.showRedes ? 1 : 0
        if (t.key == 'reembolso') t.show = self.showReembolsos ? 1 : 0
        if (t.key == 'carencia') t.show = self.showCarencias ? 1 : 0
        if (t.key == 'proc_obrigatorios') t.show = self.showProcObrigatorios ? 1 : 0
        if (t.key == 'proc_diferenciados') t.show = self.showProcDiferenciados ? 1 : 0
        if (t.key == 'docs') t.show = self.showDocs ? 1 : 0
        if (t.key == 'info') t.show = self.showInfos ? 1 : 0
        if (t.key == 'arquivos') t.show = self.showArquivos ? 1 : 0
        return t
      })
      this.tabs = this.tabsObj.filter(t => t.show == 1).map(t => t.key)
      this.$store.dispatch('operadora/updateOperadora', this.operadora)
    },
    checked(filters, type) {
      let ops = [...filters]
      let filter = ops.filter(filter => filter.data.item == type)
      let obj = filter.length > 0 ? filter.shift(1) : {}
      return obj.hasOwnProperty('state') ? obj.state.indeterminate || obj.state.checked : false
    },
    children(filters, key) {
      let filter = filters.find(f => f.data.item == key)
      return filter ? filter.children : []
    },
    valoresKey() {
      // Define a chave que sera utilizada no objeto grupo para exibição da tabela
      return this.valores.indexOf('00-18') == -1 ? 'plano' : 'idade'
    },
    showTabs(key) {
      return this.tabsObj && this.tabsObj.length > 0 ? this.tabsObj.filter(t => t.key == key && t.show == "1").length > 0 : false
    },
    normalizeFields(fields) {
      return fields ? fields.map(f => {
        if (f.hasOwnProperty('isRowHeader')) {
          return {
            isRowHeader: parseInt(f.isRowHeader) ? true : false,
            key: f.key,
            stickyColumn: parseInt(f.stickyColumn) ? true : false,
          }
        }
        return f
      }) : []
    },
  },
  computed: {
    ...mapGetters('usuario', ['getSession']),
    ...mapGetters('share', ['getCotacao']),
    ...mapGetters('operadora', [
      'getTabs',
      'getOperadora',
      'getProduto',
      'getPlanos',
      'getPlanosPorRede',
      'getGroupTree',
      'getGrupos'
    ]),
    ...mapGetters('modalFilter', ['getFilters']),
    ...mapGetters('regiao', {
      loadingRegiao: 'getLoading'
    }),
    ...mapGetters('reembolso', {
      loadingReembolsos: 'getLoading'
    }),
    ...mapGetters('carencia', {
      loadingCarencias: 'getLoading'
    }),
    ...mapGetters('procedimento', {
      loadingProcedimentos: 'getLoading'
    }),
    ...mapGetters('docs', {
      loadingDocs: 'getLoading'
    }),
    ...mapGetters('info', {
      loadingInfos: 'getLoading'
    }),
    ...mapGetters('arquivo', {
      loadingArquivos: 'getLoading'
    }),
    baseStylesRede () {
      return {
        '--width': (this.$isMobile ? 120 : 200) + 'px',
        '--headerHeight': this.$refs.headerRede ? this.$refs.headerRede.clientHeight + 'px' : '0',
        '--minWidth': (100 + this.planos.length * 180) + 'px'
      }
    },
    baseStylesReembolso() {
      return {
        '--width': (this.$isMobile ? 150 : 200) + 'px',
        '--headerHeight': this.$refs.headerReembolso ? this.$refs.headerReembolso.clientHeight + 'px' : '0',
        '--minWidth': (100 + this.planos.length * 180) + 'px'
      }
    },
    operadoraLogoTipo() {
      return this.operadora.logotipoavo ? this.operadora.logotipoavo : this.operadora.logotipo
    },
    title() {
      let title = ''
      title = `${this.produto.nome} - ${this.operadora.cidade.cidade} - ${this.operadora.cidade.uf}`
      return title
    },
    subtitle() {
      let subtitle = ''
      subtitle += (this.operadora.tipo_plano != 'o') ? 'Saúde' : 'Odonto'
      subtitle += (this.operadora.contratacao == 'a') ? ' - Adesão' : (this.operadora.contratacao == 'pf' ? ' - PF' : ' - PME')
      subtitle += (this.operadora.administradora) ? ` - ${this.operadora.administradora.nome}` : ''
      subtitle += (this.operadora.entidade) ? ` - ${this.operadora.entidade.nome}` : ''
      subtitle += (this.operadora.credenciado) ? ' - ' + this.operadora.credenciado.nome : ''
      return subtitle
    },
    textCop() {
      return this.operadora.coparticipacao > 1 ? 'indiferente' : this.operadora.coparticipacao == 1 ? 'sim' : 'não'
    },
    textCom() {
      return this.operadora.compulsorio > 1 ? 'indiferente' : this.operadora.compulsorio == 1 ? 'sim' : 'não'
    },
    textAcom() {
      return this.operadora.acomodacao > 1 ? 'indiferente' : this.operadora.acomodacao == 1 ? 'apartamento' : 'enfermaria'
    },
    textMei() {
      return this.operadora.mei > 1 ? 'indiferente' : this.operadora.mei == 1 ? 'sim' : 'não'
    },
    credenciados() {
      let id = this.regiao?.id
      let rede = this.redes.find(r => r.regiao_id == id)
      return rede?.rede ? rede.rede : []
    },
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter
      };
    },
    mapCenter() {
      return this.markers[1].position;
    },
    reembolsosProcedimentos() {
      let self = this
      let reembolsos = JSON.parse(JSON.stringify(this.reembolsos));
      let filtersReembolsosChildren = this.children(this.getFilters, 'reembolso')
      let reembolsosReduce = reembolsos.reduce((acc, obj) => {
        if (!Array.isArray(acc)) acc = []
        obj.valores.forEach((r) => {
          let i = acc.findIndex(p => p.aId == r.aId)
          if (i == -1) {
              acc.push({ aId: r.aId, nome: r.nome })
          }
        })
        return acc
      }, [])
      return reembolsosReduce
        .filter(r => self.checked(filtersReembolsosChildren, r.nome))
    },
    search() {
      const tipo = this.tabs[this.tabIndex]
      const self = this;
      if (tipo == 'valores') {
        self.loadingValores = false
        let grupos = JSON.parse(JSON.stringify(self.grupos));
        let filtersValoresChildren = self.children(self.getFilters, 'valor')
        return grupos.map(grupo => {
          let data_inicio = new Date(grupo.data_inicio),
              now = new Date()
          data_inicio.setDate(data_inicio.getDate() + 1)
          grupo.data_inicio = data_inicio <= now ? false : grupo.data_inicio
          grupo.items = grupo.items.filter(
            i => self.checked(
              filtersValoresChildren,
              i[self.valoresKey()]
            )
          )
          return grupo
        }).filter(grupo => {
          let term = self.buscaValores.replace(/[\s]/, '.*')
          return grupo.nome ? grupo.nome.search(new RegExp('('+term+')', 'ig')) !== -1 : true
        })
      } else if (tipo == 'rede') {
        let regioes = JSON.parse(JSON.stringify(self.regioes));
        let filterRedeChildren = self.children(self.getFilters, 'rede')
        regioes = regioes.filter(regiao => {
          if (!self.checked(filterRedeChildren, regiao.nome)) return false
          let filterRegiaoChildren = self.children(filterRedeChildren, regiao.nome)
          let term = self.replaceSepecialChars(self.buscaRedes).replace(/[\s]/, '.*')
          let reg = new RegExp('('+term+')', 'ig');
          regiao.cidades = regiao.cidades.filter(cidade => {
            if (!self.checked(filterRegiaoChildren, cidade.nome)) return false
            let filterCidadeChildren = self.children(filterRegiaoChildren, cidade.nome)
            let hospitais = []
            let laboratorios = []
            let centros = []
            if (cidade.credenciados.hasOwnProperty('Hospitais') && self.checked(filterCidadeChildren, 'Hospitais')) {
              let filterHospitalChildren = self.children(filterCidadeChildren, 'Hospitais')
              hospitais = cidade.credenciados['Hospitais'].filter(cred => {
                if (!self.checked(filterHospitalChildren, cred.id)) return false
                if (reg.test(self.replaceSepecialChars(cred.nome))) {
                  return true
                }
              })
            }
            if (cidade.credenciados.hasOwnProperty('Laboratórios') && self.checked(filterCidadeChildren, 'Laboratórios')) {
              let filterLaboratorioChildren = self.children(filterCidadeChildren, 'Laboratórios')
              laboratorios = cidade.credenciados['Laboratórios'].filter(cred => {
                if (!self.checked(filterLaboratorioChildren, cred.id)) return false
                if (reg.test(self.replaceSepecialChars(cred.nome))) {
                  return true
                }
              })
            }
            if (cidade.credenciados.hasOwnProperty('Centros Médicos') && self.checked(filterCidadeChildren, 'Centros Médicos')) {
              let filterCentroMedicoChildren = self.children(filterCidadeChildren, 'Centros Médicos')
              centros = cidade.credenciados['Centros Médicos'].filter(cred => {
                if (!self.checked(filterCentroMedicoChildren, cred.id)) return false
                if (reg.test(self.replaceSepecialChars(cred.nome))) {
                  return true
                }
              })
            }
            cidade.credenciados['Hospitais'] = hospitais
            if (hospitais.length == 0) delete cidade.credenciados['Hospitais']
            cidade.credenciados['Laboratórios'] = laboratorios
            if (laboratorios.length == 0) delete cidade.credenciados['Laboratórios']
            cidade.credenciados['Centros Médicos'] = centros
            if (centros.length == 0) delete cidade.credenciados['Centros Médicos']
            if (Object.values(cidade.credenciados).flat().length > 0) {
              return true
            } else {
              return reg.test(self.replaceSepecialChars(cidade.nome))
            }
          })
          if (regiao.cidades.length > 0) {
            return true
          } else {
            return reg.test(self.replaceSepecialChars(regiao.nome))
          }
        })
        return regioes
      } else if (tipo == 'carencia') {
        let carencias = JSON.parse(JSON.stringify(self.carenciasIsArray));
        let filtersCarenciaChildren = self.children(self.getFilters, 'carencia')
        return carencias
          .filter(c => self.checked(filtersCarenciaChildren, c.catNome))
          .filter(carencia => {
            let term = self.busca.replace(/[\s]/, '.*')
            return carencia.catNome ? carencia.catNome.search(
              new RegExp('('+term+')', 'ig')
            ) !== -1 : true
          })

      } else if (tipo == 'proc_obrigatorios') {
        let proc_obrigatorios = JSON.parse(JSON.stringify(self.proc_obrigatorios));
        let filtersProcObriChildren = self.children(self.getFilters, 'proc_obrigatorios')
        return proc_obrigatorios
          .filter(p => {
            if (!self.checked(filtersProcObriChildren, p.nome)) return false
            let filterProcChildren = self.children(filtersProcObriChildren, p.nome)
            p.procs = p.procs.filter(pr => self.checked(filterProcChildren, pr.nome))
            return p.procs.length > 0
          })
          .filter(proc => {
            let term = self.busca.replace(/[\s]/, '.*')
            return proc.nome ? proc.nome.search(
              new RegExp('('+term+')', 'ig')
            ) !== -1 : true
          })
      } else if (tipo == 'proc_diferenciados') {
        let proc_diferenciados = JSON.parse(JSON.stringify(self.proc_diferenciados));
        let filtersProcDifChildren = self.children(self.getFilters, 'proc_diferenciados')
        return proc_diferenciados
          .filter(p => self.checked(filtersProcDifChildren, p.nome))
          .filter(proc => {
            let term = self.busca.replace(/[\s]/, '.*')
            return proc.nome ? proc.nome.search(
              new RegExp('('+term+')', 'ig')
            ) !== -1 : true
          })
      } else if (tipo == 'docs') {
        let docs = JSON.parse(JSON.stringify(self.docsIsArray));
        let filtersDocsChildren = self.children(self.getFilters, 'docs')
        return docs
          .filter(d => self.checked(filtersDocsChildren, d.catNome))
          .filter(doc => {
            let term = self.busca.replace(/[\s]/, '.*')
            return doc.catNome ? doc.catNome.search(
              new RegExp('('+term+')', 'ig')
            ) !== -1 : true
          })
      } else if (tipo == 'info') {
        let infos = JSON.parse(JSON.stringify(self.infosIsArray));
        let filtersInfosChildren = self.children(self.getFilters, 'infos')
        return infos
          .filter(i => self.checked(filtersInfosChildren, i.catNome))
          .filter(info => {
            let term = self.busca.replace(/[\s]/, '.*')
            return info.catNome ? info.catNome.search(
              new RegExp('('+term+')', 'ig')
            ) !== -1 : true
          })
      } else if (tipo == 'arquivos') {
        let arquivos = JSON.parse(JSON.stringify(self.arquivos));
        let filtersArquivosChildren = self.children(self.getFilters, 'arquivos')
        return arquivos
          .filter(a => self.checked(filtersArquivosChildren, a.nome))
          .filter(arquivo => {
            let term = self.busca.replace(/[\s]/, '.*')
            return arquivo.nome.search(new RegExp('('+term+')', 'ig')) !== -1
          })
      }
    },
    showValores() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'valor') : this.getFilters.length == 0 ? true : false
    },
    showRedes() {
      return this.getFilters.length > 0 && this.operadora.tipo_plano != 'o' ? this.checked(this.getFilters, 'rede') : this.getFilters.length == 0 ? true : false
    },
    showReembolsos() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'reembolso') : this.getFilters.length == 0 ? true : false
    },
    showCarencias() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'carencia') : this.getFilters.length == 0 ? true : false
    },
    showProcObrigatorios() {
      return this.getFilters.length > 0 && this.operadora.tipo_plano == 'o' ? this.checked(this.getFilters, 'proc_obrigatorios') : false
    },
    showProcDiferenciados() {
      return this.getFilters.length > 0 && this.operadora.tipo_plano == 'o' ? this.checked(this.getFilters, 'proc_diferenciados') : false
    },
    showDocs() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'docs') : this.getFilters.length == 0 ? true : false
    },
    showInfos() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'infos') : this.getFilters.length == 0 ? true : false
    },
    showArquivos() {
      return this.getFilters.length > 0 ? this.checked(this.getFilters, 'arquivos') : this.getFilters.length == 0 ? true : false
    },
    loadingAll() {
      return !this.loadingValores &&
        !this.loadingRegiao &&
        !this.loadingReembolsos &&
        !this.loadingCarencias &&
        !this.loadingProcedimentos &&
        !this.loadingDocs &&
        !this.loadingInfos &&
        !this.loadingArquivos
    }
  },
  watch: {
    tabIndex() {
      this.busca = ''
    }
  },
  async created() {
    const id = localStorage.getItem(process.env.VUE_APP_CLIENT_ID)
    const json = JSON.parse(id)
    let corretor = json?.user

    this.tabs = ['valores', 'rede', 'reembolso', 'carencia', 'docs', 'info', 'arquivos']

    this.source = this.$route.query.source || '';

    await this.cotacao(this.hash)
      .then(() => this.loadingCotacao = false)
      .catch(() => this.loadingCotacao = false)

    const cotacao = this.getCotacao
    let operadora = cotacao.detalhes.operadora

    if (!corretor && this.source != 'iframe') {
      await this.enviar({ codigo: this.hash, tipo: 1 })
        .then(() => this.loadingCotacao = false)
        .catch(() => this.loadingCotacao = false)
    }

    this.cotacaoId = cotacao.id
    this.indicacao = cotacao.indicacao
    this.cliente_nome = cotacao.nome
    this.cliente_email = cotacao.email
    this.cliente_telefone = cotacao.telefone
    this.cotacaoData = cotacao.data
    this.cotacaoUf = cotacao.detalhes.uf
    this.cotacaoTitulo = cotacao.titulo
    this.cotacaoMensagem = cotacao.detalhes.mensagem.replace(/(\n|\r)/ig, '<br/>');
    this.cotacaoArquivos = cotacao.detalhes.arquivos
    this.filtros = cotacao.parametros.filtros
    this.filters = cotacao.opcoes.filters

    this.user = cotacao.detalhes.user
    await this.save({ key: "user", value: this.user });

    this.operadora = operadora
    this.produto = operadora.produto

    this.validade = cotacao.validade
    this.vencida = cotacao.vencida
    this.tabsObj = operadora.tabs
    this.planos = operadora.planos
    this.planosPorRede = operadora.planosPorRede
    this.valores = operadora.valores
    this.grupos = operadora.grupos

    this.operadora['grupos'] = this.grupos

    this.administradora = operadora.administradora
    this.entidade = operadora.entidade

    let promises = [];
    if (!this.vencida) {
      if (this.operadora.tipo_plano != 'o') {
        promises.push(this.recuperaRedes())
      } else if (this.operadora.tipo_plano == 'o') {
        promises.push(this.recuperaProcedimentos())
      }
      
      promises.push(this.recuperaReembolsos())
      promises.push(this.recuperaCarencia())
      promises.push(this.recuperaDocs())
      promises.push(this.recuperaInfo())
      promises.push(this.recuperaArquivos())
    }
    Promise.all(promises).then(() => {
      this.loadFilters()
    });
  },
};
</script>
<style scope lang="scss">
.content-wrapper {
  background-color: #ecf0f5;
  -webkit-transition: -webkit-transform .3s ease-in-out,margin .3s ease-in-out;
  -moz-transition: -moz-transform .3s ease-in-out,margin .3s ease-in-out;
  -o-transition: -o-transform .3s ease-in-out,margin .3s ease-in-out;
  transition: transform .3s ease-in-out,margin .3s ease-in-out;
  margin-left: 230px;
  z-index: 820;
  padding: 15px;
  min-height: 780px;
}
.sidebar-collapse .main-sidebar,
.sidebar-collapse #header-logo-text {
  display: none;
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none!important;
  }
  .main-header .navbar {
    margin: 0 !important;
  }
  .content-wrapper, .main-footer {
    margin-left: 0;
  }
}
.sidebar-collapse .content-wrapper,
.sidebar-collapse nav.navbar.navbar-static-top {
  margin-left: 0 !important;
}
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px;
    z-index: 840;
  }
}
@media (max-width: 767px) {
  .main-sidebar {
    -webkit-transform: translate(-230px, 0);
    -ms-transform: translate(-230px, 0);
    -o-transform: translate(-230px, 0);
    transform: translate(-230px, 0);
  }
  .main-sidebar {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@media (max-width: 767px) {
  .sidebar-open .content-wrapper, .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
    -ms-transform: translate(230px, 0);
    -o-transform: translate(230px, 0);
    transform: translate(230px, 0);
  }
}
.navbar-nav {
  margin: 7.5px -15px;
}
@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
}
.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}
@media (min-width: 768px) {
  .navbar-nav>li>a {
    padding-top: 11px;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .content-wrapper, .main-footer {
      margin-left: 0 !important;
  }
}
.b-overlay {
  z-index: 999 !important;
}
.redes,
.reembolsos {
  width: 100%;
  height: 100% !important;
  min-height: 100% !important;
  overflow-x: auto;
  overflow-y: visible;
  & table  {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    min-width: var(--minWidth);
    margin-bottom: 0 !important;
    & th {
      font-weight: normal;
    }
  }
  & .eixo {
    z-index: 3 !important;
  }
  & th.eixo {
    width: var(--freezeWidth);
  }
  & thead tr:first-child th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  & table td:first-child,
  & table th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
  }
  & table th:first-child {
    width: var(--width);
    padding-left: 24px;
  }
  & thead tr:nth-child(2) th {
    background-color: #f2f2f2;
    -webkit-print-color-adjust: exact;
    position: -webkit-sticky;
    position: sticky;
    top: 75px;
    left: 0;
    z-index: 2;
    height: 40px;
  }
  & .table-striped tbody > tr:nth-of-type(2n) {
    background-color: #fff;
  }
  & table tr.alternate th,
  & table tr.alternate td {
    background-color: #f2f2f2;
    -webkit-print-color-adjust: exact;
  }
  & table {
    &.overflowing-both,
    &.overflowing-left {
      tbody th:first-child {
        box-shadow: -2px 20px 20px rgba(135, 135, 135, 0.5);
      }
    }
  }
}
.scroll-button {
  position: absolute;
  margin-top: 0px;
  min-height: 35px;
  width: 15px;
  overflow: hidden;
  background: #009b3a;
  color: #fff !important;
  z-index: 100;
  border: 1px solid #058634;
}
.scroll-button.float-left {
  left: 0px;
}
.scroll-button.float-right {
  right: 0px;
}
.no-padding {
  padding: 0;
}
.no-margin {
  margin: 0;
}
a.voltar {
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
}
div.logo-operadora {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
div.info-plano-selecionado {
  display: inline-block;
  vertical-align: top;
  padding: 5px 8px;
  & h4 {
    margin: 0;
  }
  & h6 {
    font-weight: normal;
    margin-top: 4px;
  }
  & p {
    font-size: 12px;
    margin: 5px 0px;
  }
}
.nav-link {
  font-size: 15px;
}
div.grupo {
  & .btn .b-icon.bi,
  .nav-link .b-icon.bi,
  .dropdown-toggle .b-icon.bi,
  .dropdown-item .b-icon.bi,
  .input-group-text .b-icon.bi,
  .btn .fa-user-friends,
  .btn .fa-calendar-check,
  .btn .fa-hourglass-end,
  .btn .fa-hand-holding-usd,
  .btn .fa-globe,
  span {
    color: #9E9E9E;
    font-weight: 400;
  }
  & span {
    text-transform: none;
  }
}
// .card-container {
//   /* position: absolute; */
//   /* z-index: 2; */
// }
.table-responsive {
  overflow-x: auto !important;
}
div[role="tablist"] {
  button.btn {
    text-align: left;
    box-shadow: none;
    border-radius: 0;
  }
  .b-table-sticky-header {
    max-height: none;
  }
  table {
    & th:not(:first-child),
    & td:not(:first-child) {
      text-align: center;
    }
    & th, & td {
      font-size: 0.8rem!important;
      padding: 8px 4px !important;
      & > div {
        font-size: 14px;
      }
    }
  }
}
div.modal-body {
  & div.list-group {
    height: 350px;
    overflow-y: auto;
  }
}
.travel-map {
  height: 400px;
}
#modal-localizacao {
  .modal-content {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
}
.col-12.map {
  iframe {
    width: 100%;
    max-height: 350px;
  }
  small a {
    text-align: center !important;
    display: block;
    padding: 5px;
    border: 1px solid #004085;
    background-color: #007bff;
    color: #fff !important;
    font-size: 15px;
    width: 200px;
    margin: 0 auto;
    border-radius: 8px;
  }
}
.carencias .card-body table,
.docs .card-body table,
.infos .card-body table {
  border: none;
  width: 100% !important;
}

.carencias .card-body > div > div,
.docs .card-body > div > div,
.infos .card-body > div > div {
  padding: 5px;
}
.carencias .card-body,
.docs .card-body,
.infos .card-body {
  & p, & span, & strong {
    font-size: 14px !important;
  }
}
div.legendas {
  font-size: 13px;
  letter-spacing: 0.4px;
}
.card-header .collapsed > .fa-chevron-up,
.card-header .not-collapsed > .fa-chevron-down {
  display: none;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  font-weight: bold;
  background-color: #7dd488;
  border-color: #67ad70;
}
.card-aviso .card-header,
.card-cabecalho .card-header {
  padding: .75rem 1.25rem !important;
}
.card-aviso .card-title,
.card-cabecalho .card-title {
  font-size: 0.9rem;
  font-weight: bold !important;
}
.card-aviso p,
.card-cabecalho p {
  font-size: 0.8rem;
}
.card-aviso .card-title,
.card-aviso p {
  color: #b92331;
}
.card-aviso .btn,
.card-cabecalho .btn {
  font-size: 12px !important;
  line-height: 1.5 !important;
  text-transform: lowercase;
}
.break-all {
  word-break: break-all;
}
.credenciado_obs {
  max-width: initial;
}
</style>